export default function Privacy(){
    return (
        <div>
          {/* generate a privacy page with tailwindcss for Flitter is a platform allowing people who authentify through Google Authentication to automate the generation of posts that are scheduled to be published on Facebook, Twitter, Instagram, LinkedIn, website blog, SMS and Mailchimp. Generate privacy policy for Flitter. */}
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <title>Privacy Policy</title>  
          <meta name="description" content="Generate privacy policy for Flitter." />
          <meta name="author" content="Flitter" />
          <link rel="stylesheet" href="https://unpkg.com/tailwindcss/dist/tailwind.min.css" />
          <style dangerouslySetInnerHTML={{__html: "\n        .bg-privacy {\n            background-color: #f7fafc;\n        }\n        " }} />
          <div className="container mx-auto px-4">
            <div className="max-w-2xl mx-auto">
              <div className="text-center">
                <h1 className="text-4xl font-bold">Privacy Policy</h1>
                <p className="text-gray-600">Last updated: 2024-05-19</p>
              </div>
              <div className="mt-8">
                <h2 className="text-2xl font-bold">Introduction</h2>
                <p className="mt-4">Thank you for choosing to be part of our community at Flitter ("Company", "we", "us", or "our") a product of <a href="https://andersontaylor.com" target="_blank" className="underline">Anderson Taylor</a>. We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice, or our practices with regards to your personal information, please contact us at <a href="mailto:info@flitter.pro">info@flitter.pro</a> .</p>
                <p className="mt-4">When you visit our website <a href="https://flitter.pro">https://flitter.pro</a> (the "Website"), and more generally, use any of our services (the "Services", which include the Website), we appreciate that you are trusting us with your personal information. We take your privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Services immediately.</p>
                <p className="mt-4">This privacy notice applies to all information collected through our Services (which, as described above, includes our Website), as well as, any related services, sales, marketing or events.</p>
                <p className="mt-4">Please read this privacy notice carefully as it will help you understand what we do with the information that we collect.</p>
              </div>
              {/* <div class="mt-8">
              <h2 class="text-2xl font-bold">Table of contents</h2>
              <ul class="mt-4">
                  <li><a href="#infocollect">1. WHAT INFORMATION DO WE COLLECT?</a></li>
                  <li><a href="#infouse">2. HOW DO WE USE YOUR INFORMATION?</a></li>
                  <li><a href="#infoshare">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a></li>
                  <li><a href="#infostore">4. HOW LONG DO WE KEEP YOUR INFORMATION?</a></li>
                  <li><a href="#infominors">5. DO WE COLLECT INFORMATION FROM MINORS?</a></li>
                  <li><a href="#infocountry">6. THE LAWS OF SOME COUNTRIES DO NOT ALLOW SOME OF THE ABOVE LIMITATIONS. IF YOU ARE LOCATED IN SUCH A COUNTRY, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.</a></li>
                  <li><a href="#infopolicy">7. DO WE MAKE UPDATES TO THIS NOTICE?</a></li>
                  <li><a href="#infopolicy">8. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a></li>
                  <li><a href="#infopolicy">9. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a></li>
              </ul>
              </div> */}
              <div className="mt-8">
                <h2 className="text-2xl font-bold" id="infocollect">1. WHAT INFORMATION DO WE COLLECT?</h2>
                <p className="mt-4">Personal information you disclose to us</p>
                <p className="mt-4">In Short: We collect personal information that you provide to us.</p>
                <p className="mt-4">We collect personal information that you voluntarily provide to us when registering at the Website, expressing an interest in obtaining information about us or our products and Services, when participating in activities on the Website (such as by posting messages in our online forums or entering competitions, contests or giveaways) or otherwise contacting us.</p>
                <p className="mt-4">The personal information that we collect depends on the context of your interactions with us and the Website, the choices you make and the products and features you use. The personal information we collect may include the following:</p>
                <p className="mt-4">Personal Information Provided by You. We collect names; email addresses; and other similar information.</p>
                <p className="mt-4">Social Media Login Data. We may provide you with the option to register with us using your existing social media account details, like your Facebook, Twitter or other social media account. If you choose to register in this way, we will collect the Information described in the section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS" below.</p>
                <p className="mt-4">All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.</p>
                <p className="mt-4">Information automatically collected</p>
                <p className="mt-4">In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Website.</p>
                <p className="mt-4">We automatically collect certain information when you visit, use or navigate the Website. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Website and other technical information. This information is primarily needed to maintain the security and operation of our Website, and for our internal analytics and reporting purposes.</p>
                <p className="mt-4">Like many businesses, we also collect information through cookies and similar technologies.</p>
                <p className="mt-4">The information we collect includes:</p>
                <p className="mt-4">Log and Usage Data. Log and usage data is service-related, diagnostic, usage and performance information our servers automatically collect when you access or use our Website and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type and settings and information about your activity in the Website (such as the date/time stamps associated with your usage, pages and files viewed, searches and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps') and hardware settings).</p>
                <p className="mt-4">Device Data. We collect device data such as information about your computer, phone, tablet or other device you use to access the Website. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model Internet service provider and/or mobile carrier, operating system and system configuration information.</p>
                <p className="mt-4">Location Data. We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Website. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. Note however, if you choose to opt out, you may not be able to use certain aspects of the Services.</p>
                <p className="mt-4">Information collected from other sources</p>
                <p className="mt-4">In Short: We may collect limited data from public databases, marketing partners, social media platforms, and other outside sources.</p>
                <p className="mt-4">We may obtain information about you from other sources, such as public databases, joint marketing partners, social media platforms (such as Facebook), as well as from other third parties. Examples of the information we receive from other sources include: social media profile information.
                </p>
              </div>
              <div className="mt-8">
                <h2 className="text-2xl font-bold" id="infouse">2. HOW DO WE USE YOUR INFORMATION?</h2>
                <p className="mt-4">In Short: We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.</p>
                <p className="mt-4">We use personal information collected via our Website for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests ("Business Purposes"), in order to enter into or perform a contract with you ("Contractual"), with your consent ("Consent"), and/or for compliance with our legal obligations ("Legal Reasons"). We indicate the specific processing grounds we rely on next to each purpose listed below.</p>
                <p className="mt-4">We use the information we collect or receive:</p>
                <p className="mt-4">To facilitate account creation and logon process. If you choose to link your account with us to a third-party account *(such as your Google or Facebook account), we use the information you allowed us to collect from those third parties to facilitate account creation and logon process. See the section below headed "HOW DO WE HANDLE YOUR SOCIAL LOGINS" for further information.</p>
                <p className="mt-4">To post testimonials. We post testimonials on our Website that may contain personal information. Prior to posting a testimonial, we will obtain your consent to use your name and the content of the testimonial. If you wish to update, or delete your testimonial, please contact us at [email&nbsp;protected] and be sure to include your name, testimonial location, and contact information.</p>
                <p className="mt-4">Request feedback. We may use your information to request feedback and to contact you about your use of our Website.</p>
                <p className="mt-4">To enable user-to-user communications. We may use your information in order to enable user-to-user communications with each user's consent.</p>
                <p className="mt-4">To manage user accounts. We may use your information for the purposes of managing our account and keeping it in working order.</p>
                <p className="mt-4">To send administrative information to you. We may use your personal information to send you product, service and new feature information and/or information about changes to our terms, conditions, and policies.</p>
                <p className="mt-4">Fulfill and manage your orders. We may use your information to fulfill and manage your orders, payments, returns, and exchanges made through the Website.</p>
                <p className="mt-4">To protect our Services. We may use your information as part of our efforts to keep our Website safe and secure (for example, for fraud monitoring and prevention).</p>
                <p className="mt-4">To enforce our terms, conditions and policies for business purposes, to comply with legal and regulatory requirements or in connection with our contract.</p>
                <p className="mt-4">To respond to legal requests and prevent harm. If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.</p>
                <p className="mt-4">For other Business Purposes. We may use your information for other Business Purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Website, products, services, marketing and your experience.</p>
              </div>
              <div className="mt-8">
                <h2 className="text-2xl font-bold" id="infoshare">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h2>
                <p className="mt-4">In Short: We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.</p>
                <p className="mt-4">We may process or share data based on the following legal basis:</p>
                <p className="mt-4">Consent: We may process your data if you have given us specific consent to use your personal information in a specific purpose.</p>
                <p className="mt-4">Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.</p>
                <p className="mt-4">Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</p>
                <p className="mt-4">Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</p>
                <p className="mt-4">Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</p>
                <p className="mt-4">More specifically, we may need to process your data or share your personal information in the following situations:</p>
                <p className="mt-4">Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</p>
                <p className="mt-4">Vendors, Consultants and Other Third-Party Service Providers. We may share your data with third party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology on the Website, which will enable them to collect data about how you interact with the Website over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content and better understand online activity. Unless described in this Policy, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes.</p>
                <p className="mt-4">Business Partners. We may share your information with our business partners to offer you certain products, services or promotions.</p>
                <p className="mt-4">Other Users. When you share personal information (for example, by posting comments, contributions or other content to the Website) or otherwise interact with public areas of the Website, such personal information may be viewed by all users and may be publicly distributed outside the Website in perpetuity. If you interact with other users of our Website and register through a social network (such as Facebook), your contacts on the social network will see your name, profile photo, and descriptions of your activity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our Website, and view your profile.</p>
              </div>
              <div className="mt-8">
                <h2 className="text-2xl font-bold" id="infosec">4. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h2>
                <p className="mt-4">In Short: We may transfer, store, and process your information in countries other than your own.</p>
                <p className="mt-4">Our servers are located in. If you are accessing our Website from outside, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see "WILL YOUR INFORMATION BE SHARED WITH ANYONE?" above), in and other countries.</p>
                <p className="mt-4">If you are a resident in the European Economic Area, then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. We will however take all necessary measures to protect your personal information in accordance with this privacy policy and applicable law.</p>
              </div>
              <div className="mt-8">
                <h2 className="text-2xl font-bold" id="infosec">5. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
                <p className="mt-4">In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law.</p>
                <p className="mt-4">We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this policy will require us keeping your personal information for longer than the period of time in which users have an account with us.</p>
                <p className="mt-4">When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
              </div>
              <div className="mt-8">
                <h2 className="text-2xl font-bold" id="infomin">6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
                <p className="mt-4">In Short: We aim to protect your personal information through a system of organizational and technical security measures.</p>
                <p className="mt-4">We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Website is at your own risk. You should only access the Website within a secure environment.</p>
              </div>
              <div className="mt-8">
                <h2 className="text-2xl font-bold" id="infomin">7. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
                <p className="mt-4">In Short: You may review, change, or terminate your account at any time.</p>
                <p className="mt-4">If you are a resident in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" className="text-blue-500">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.</p>
                <p className="mt-4">If you are a resident in Switzerland, the contact details for the data protection authorities are available here: <a href="https://www.edoeb.admin.ch/edoeb/en/home.html" className="text-blue-500">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.</p>
                {/*
              <p class="mt-4">Account Information</p>
              <p class="mt-4">If you would at any time like to review or change the information in your account or terminate your account, you can:</p>
              <p class="mt-4">Log into your account settings and update your user account.</p>
              <p class="mt-4">Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with applicable legal requirements.</p>
              <p class="mt-4">Opting out of email marketing: You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list — however, we may still communicate with you, for example to send you service-related emails that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes. To otherwise opt-out, you may:</p>
              <p class="mt-4">Access your account settings and update your preferences.</p>
              <p class="mt-4">Contact us using the contact information provided.</p>
              <p class="mt-4">Call us at 1-800-555-5555</p>
              <p class="mt-4">Do California residents have specific privacy rights?</p>
              <p class="mt-4">In Short: Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</p>
              <p class="mt-4">California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</p>
              <p class="mt-4">If you are under 18 years of age, reside in California, and have a registered account with the Website, you have the right to request removal of unwanted data that you publicly post on the Website. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Website, but please be aware that the data may not be completely or comprehensively removed from our systems.</p>
              <p class="mt-4">Do we make updates to this policy?</p>
              <p class="mt-4">In Short: Yes, we will update this policy as necessary to stay compliant with relevant laws.</p>
              <p class="mt-4">We may update this privacy policy from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.</p>
              */}
              </div>
              <div className="mt-8">
                <h2 className="text-2xl font-bold" id="infomin">8. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</h2>
                <p className="mt-4">If you have questions or comments about this policy, you may email us at <a href="mailto:info@flitter.pro">info@flitter.pro</a>
                </p></div>
              <div className="mt-8">
                <h2 className="text-2xl font-bold" id="infomin">9. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h2>
                <p className="mt-4">Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please visit: <a href="https://app.flitter.pro/privacy" className="text-blue-500">https://flitter.pro/privacy</a> and leave us an email at <a href="mailto:info@flitter.pro" className="text-blue-500">info@flitter.pro</a>.</p>
                <p className="mt-4">We will respond to your request within 30 days.</p>
                <div className="my-10"></div>
              </div>
            </div>
          </div>
        </div>
      );
}