const { get_facebook_access_token } = require("./api");

export const addFacebookChannel = async (payload) => {
    const FB_GRAPH_VERSION = "v15.0";
    const base_url = `https://www.facebook.com/${FB_GRAPH_VERSION}/dialog/oauth`;
    const client_id = process.env.REACT_APP_FB_CLIENT_ID; // pages_manage_postspages_show_list
    const redirect_uri = window.location.href + "facebook/pages";
    
    //const scope = 'pages_read_engagement,pages_manage_posts,publish_to_groups,pages_manage_ads,pages_manage_engagement,page_events,business_management';
    //const scope = "pages_manage_cta%2Cpages_manage_instant_articles%2Cpages_show_list%2Cpages_messaging%2Cpages_messaging_subscriptions%2Cpage_events%2Cpages_read_engagement%2Cpages_manage_metadata%2Cpages_read_user_content%2Cpages_manage_ads%2Cpages_manage_posts%2Cpages_manage_engagement%2Cbusiness_management%2Cads_management%2Cads_read%2Cread_page_mailboxes";//"pages_messaging,pages_manage_posts,page_events,pages_read_engagement";
    const scope = "pages_manage_posts%2Cpages_show_list";
    const state = JSON.stringify({org: payload.org_id, org_name: payload.org_name});
      
    const url = `${base_url}?client_id=${client_id}&scope=${scope}&response_type=code&state=${state}&redirect_uri=${redirect_uri}`;
    console.log(redirect_uri);
    console.log(url);
    // alert("Redirecting to Facebook for authentication");
    window.location.href = url; 
}

export const addInstagramChannel = async (payload) => {
    const FB_GRAPH_VERSION = "v15.0";
    const base_url = `https://www.facebook.com/${FB_GRAPH_VERSION}/dialog/oauth`;
    const client_id = process.env.REACT_APP_FB_CLIENT_ID;
    const redirect_uri = window.location.origin + "/instagram/pages";
    //const scope = 'pages_read_engagement,pages_manage_posts,publish_to_groups,pages_manage_ads,pages_manage_engagement,page_events,business_management';
    //const scope = "pages_manage_cta%2Cpages_manage_instant_articles%2Cpages_show_list%2Cpages_messaging%2Cpages_messaging_subscriptions%2Cpage_events%2Cpages_read_engagement%2Cpages_manage_metadata%2Cpages_read_user_content%2Cpages_manage_ads%2Cpages_manage_posts%2Cpages_manage_engagement%2Cbusiness_management%2Cads_management%2Cads_read%2Cread_page_mailboxes";//"pages_messaging,pages_manage_posts,page_events,pages_read_engagement";
    const scope = "pages_manage_posts%2Cpages_show_list%2Cinstagram_basic%2Cinstagram_content_publish";
    const state = JSON.stringify({org: payload.org_id, org_name: payload.org_name});
      
    const url = `${base_url}?client_id=${client_id}&scope=${scope}&response_type=code&state=${state}&redirect_uri=${redirect_uri}`;
    window.location.href = url;
}

function getCodeFromQueryString() {
    const match = window.location.search.match(/code=([^&]+)/);
    return match ? match[1] : null;  
}

export const handleAjoutFacebookChannel = async (org_id, callback) => {
    
    //Get code from query string
    const code = getCodeFromQueryString();
    if(code){
        const auth = await get_facebook_access_token({code,org_id});
        if(typeof callback == "function") callback();
    }
}

export const getLinkedinLoginURL = (type, org_id, org_name) => {
    let app_id = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
    const app_redirect_url = window.location.origin  + "/linkedin/connect";
    let scope = "";
    let state = "";
   
    if(type === "organization"){
        app_id = process.env.REACT_APP_LINKEDIN_CLIENT_ID_BUSINESS;
        scope = 'w_organization_social+rw_organization_admin+r_basicprofile+r_organization_social';
        state = JSON.stringify({org: org_id, org_name: org_name, type : "organization"});
    }else{
        app_id = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
        scope = 'r_liteprofile+w_member_social';
        state = JSON.stringify({org: org_id, org_name: org_name, type : "personal"});
    }
    state = btoa(state);
    const redirection = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${app_id}&redirect_uri=${app_redirect_url}&scope=${scope}&state=${state}`;
    return redirection;
}

export const openLinkedinLogin = () => {
    const app_id = process.env.REACT_APP_LINKEDIN_CLIENT_ID_LOGIN;
    const app_redirect_url = window.location.origin + "/linkedin/login";
    const scope = 'r_liteprofile+r_emailaddress';
    let state = JSON.stringify({type : "personal"});
    //convert state to base64
    state = btoa(state);
    const redirection = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${app_id}&redirect_uri=${app_redirect_url}&scope=${scope}&state=${state}`;
    console.log(redirection);
    window.location.href = redirection;

}

export const getTwitterLoginURL = () => {
    
}