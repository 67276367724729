import human from "human-time";
import { useEffect, useState } from "react";
import DashboardLayout from "../../components/DashboardLayout";

import passing_with_dog from '../../res/img/passing_with_dog.svg';
import { check_user_connection, get_all_users, get_user, update_user } from "../../utils/api";
import AdminLayout from "./AdminLayout";

const people = [
    {
        name: 'Lindsay Walton',
        title: 'Front-end Developer',
        department: 'Optimization',
        email: 'lindsay.walton@example.com',
        role: 'Member',
        image:
            'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
]

export default function Users() {

    const [global_state, set_global_state] = useState({
        users: {
            docs: []
        },
    });

    const get_sys_users = async () => {
        const res = await get_all_users(1, 200);
        set_global_state({
            ...global_state,
            users: res.users,
        });
    }

    const handleClick = async (action, person) => {
        try {
            update_user(person._id, { ...person, status: action });
        } catch (err) {
            alert(JSON.stringify(err));
        }
    }

    const handleAccess = async (person) => {
        try {
            const res = await check_user_connection({ email: person.email });
            console.log(res);
            localStorage.setItem('token', res.token);
            localStorage.setItem('user', JSON.stringify(res.user));
            window.location.href = '/';
        } catch (err) {
            alert(JSON.stringify(err));
        }
    }

    useEffect(() => {
        get_sys_users();
    }, []);


    return (
        <AdminLayout>
            <div className="col-span-12 bg-white py-2">
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-xl font-semibold text-gray-900">Users</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                A list of all the users in your account including their name, title, email and role.
                            </p>
                            <div className="my-4">
                                Total users : {global_state.users && global_state.users.docs && global_state.users.docs.length && global_state.users.total} users.
                            </div>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                            {/* <button
                                type="button"
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                            >
                                Add user
                            </button> */}
                        </div>
                    </div>
                    <div className="mt-8 flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                    Name
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Created at
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Status
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {global_state.users && global_state.users.docs && global_state.users.docs.length && global_state.users.docs.map((person) => (
                                                <tr key={person.email}>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                                        <div className="flex items-center">
                                                            <div className="h-10 w-10 flex-shrink-0">
                                                                <img className="h-10 w-10 rounded-full" src={person.picture} alt="" />
                                                            </div>
                                                            <div className="ml-4">
                                                                <div className="font-medium text-gray-900">{person.names}</div>
                                                                <div className="text-gray-500">{person.email}</div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        <div className="text-gray-900">{person.provider}</div>
                                                        <div className="text-gray-500">{person.history && person.history.date ? human(new Date(person.history.date)) : ""}</div>
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        {person.status === 'active' && (
                                                            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                                                Active
                                                            </span>)}
                                                        {person.status === 'wait' && (
                                                            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                                                Waiting
                                                            </span>)}
                                                        {
                                                            person.status === 'suspended' && (
                                                                <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
                                                                    Suspended
                                                                </span>
                                                            )
                                                        }
                                                    </td>

                                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                        {
                                                            person.role === 'superadmin' ? <span className="inline-flex rounded-full bg-blue-100 px-2 text-xs font-semibold leading-5 text-blue-800">Admin</span> :
                                                                <>
                                                                    {
                                                                        person.status === 'active' && (
                                                                            <button onClick={() => handleClick("suspended", person)} type="button" className="hover:bg-red-200 bg-red-100 text-red-800 px-4 py-2 rounded">
                                                                                Suspend
                                                                            </button>
                                                                        )
                                                                    }
                                                                    {
                                                                        person.status === 'suspended' && (
                                                                            <button onClick={() => handleClick("active", person)} type="button" className="hover:bg-green-200 bg-green-100 text-green-800 px-4 py-2 rounded">
                                                                                Activate
                                                                            </button>
                                                                        )
                                                                    }
                                                                    {
                                                                        person.status === 'wait' && (
                                                                            <button onClick={() => handleClick("active", person)} type="button" className="hover:bg-green-200 bg-green-100 text-green-800 px-4 py-2 rounded">
                                                                                Activate
                                                                            </button>
                                                                        )
                                                                    }
                                                                </>
                                                        }
                                                        <button onClick={() => handleAccess(person)} type="button" className="hover:bg-sky-200 bg-sky-100 text-sky-800 px-4 py-2 rounded">
                                                            Access
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}
