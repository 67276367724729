import DashboardLayout from "../../components/DashboardLayout";

import { CheckIcon, ClockIcon } from '@heroicons/react/24/outline'
import { Link, useParams } from "react-router-dom";
import { get_ls_checkout_url, get_organization_details, get_price_list, get_stripe_session_url } from "../../utils/api";
import { useEffect, useState } from "react";
import human from "human-time";
import Pricing from "./Pricing";
import { get_local_price_list } from "../../utils/pricing";
import Loading from "../../components/Loading";
import {Dot} from "react-animated-dots";
import { setDocumentHeaders } from "../../utils/document";

import i18next from "../../i18next";
const { t } = i18next;


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

let is_loading_plans = false;
export default function PayLemonSqueezy(props) {
    const { org_id, org_name } = useParams();
    setDocumentHeaders(t("pay_membership_name", {org_name}));
    const [global_state, set_global_state] = useState({
        org: {},
        schedule: {},
        pricing: {},
        schedule_id: null
    });
    const [loading_pyt_url, set_loading_pyt_url] = useState(-1);
    const [loading_plans, set_loading_plans] = useState(false);

    const { schedule } = global_state;
    let schedule_ = null;

    const get_ls_plans = async () => {
        if(is_loading_plans) return;
        let resp;
        try{
            is_loading_plans = true;
            set_loading_plans(true);
            resp = await get_price_list(org_id);
            is_loading_plans = false;
            set_loading_plans(false);
        }catch(ex){
            is_loading_plans = false;
            set_loading_plans(false);
            //alert("Error loading plans, please refresh the page");
            return;
        }        
        
        const { schedule } = resp;
        let prices = resp.price_list;
        let prices_ = [];

        console.log(resp);

        const plans_order = ["starter", "essentials", "pro", "business-1"];// "business"];
        const prices_list = get_local_price_list();

        const per_month_posts = schedule.period === 'week' ? parseInt(schedule.number_of_posts) * 4 : parseInt(schedule.number_of_posts) * 30;

        for (var i = 0; i < plans_order.length; i++) {
            for (var j = 0; j < prices.length; j++) {
                // alert(plans_order[i]);
                if (prices[j].attributes.slug === plans_order[i]) {
                    
                    // alert(prices[j].attributes.slug + " " + plans_order[i] + " " + per_month_posts + " " + prices_list[prices[j].attributes.slug].posts);
                    prices[j].posts_per_month = posts_per_month;
                    prices[j].title = plans_order[i];
                    // console.log(per_month_posts, prices_list[prices[j].attributes.slug].posts);
                    prices[j].available = per_month_posts <= prices_list[prices[j].attributes.slug].posts;//prices[j].attributes.slug;

                    const duration = 12;
                    const next_payement_date = new Date().setMonth(new Date().getMonth() + duration);
                    prices_.push({
                        ...prices[j],
                        mostPopular: i === 2 ? true : false
                    })
                    break;
                }
            }
        }

        set_global_state({
            ...global_state,
            pricing: { tiers: prices_ },
            schedule_id: resp.schedule._id,
            schedule: resp.schedule
        });
        console.log(prices);
    }

    const posts_per_day = schedule.period === 'day' ? parseInt(schedule.number_of_posts) : (parseInt(schedule.number_of_posts) / 7).toFixed(2);
    const posts_per_week = schedule.period === 'week' ? parseInt(schedule.number_of_posts) : (parseInt(schedule.number_of_posts) * 7).toFixed(2);
    const posts_per_month = schedule.period === 'week' ? parseInt(schedule.number_of_posts) * 4 : posts_per_day * 30;



    useEffect(() => {
        get_ls_plans(org_id);
    }, [global_state.org.length, org_id]);

    return (
        <DashboardLayout>
            <div className="mx-auto max-w-8xl bg-white col-span-12 pt-8 pb-24 px-2 lg:px-1">
                {!loading_plans ? <div>
                    <h3 className="text-xl font-bold text-center tracking-tight text-gray-900 sm:text-2xl sm:leading-none lg:text-3xl">
                        {t("choose_membership_for_pubcm", {org_name})}
                    </h3>

                    <div className="text-center text-32 mt-2">
                        <div className="text-sm">{t("your_schedule_generate_approximately",{posts_per_month})}</div>
                        <div className=" ">
                            <Link className="text-pubcm2 px-2 text-xs " to={`/schedule/setup/${org_id}/${org_name}`}> 
                            <ClockIcon className="h-4 w-4 inline" /> {t("update_schedule")}</Link>
                        </div>
                    </div>

                    <Pricing schedule_id={global_state.schedule_id} org_id={org_id} org_name={org_name} monthly_posts={posts_per_month} tiers={global_state.pricing.tiers} />

                    <div className="mt-6 mx-auto w-1/2 text-gray-500 text-center">
                        <div className=" text-xl">
                            {t("thank_you_for_choosing_pubcm_we_are_glad")}
                        </div>

                    </div>
                </div> : <div className="w-full text-center bg-white col-span-12 pt-8 pb-24 px-2 lg:px-1">
                        <div className="block w-80  text-center">
                        <div style={{display:"flex",height:"100px",flexDirection:"column", textAlign:"center", alignItems:"center",justifyContent:"center"}}><Loading style={{display:"inline-block"}}/></div>
                        </div>
                        {t("loading_plans")} <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot>
                    </div>
                }
            </div>
        </DashboardLayout>
    )
}
