import { Switch } from "@headlessui/react";
import { Cog6ToothIcon } from "@heroicons/react/20/solid";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { Dot } from "react-animated-dots";
import { useParams, useNavigate } from "react-router-dom";

import DashboardLayout from "../../../components/DashboardLayout";
import Loading from "../../../components/Loading";
import { get_organization_details, get_organization_details_info, save_schedule } from "../../../utils/api";
import { setDocumentHeaders } from "../../../utils/document";
import { get_local_price_list, get_price_list } from "../../../utils/pricing";
import Checkout from "./Checkout";
import AddImages from "./schedule-components/AddImages";
import IncludeContact from "./schedule-components/IncludeContact";
import NumberWords from "./schedule-components/NumberWords";
import PostingLanguage from "./schedule-components/PostingLanguage";
import RadioPostsNumber from "./schedule-components/RadioPostsNumber";
import RadioWeekDay from "./schedule-components/RadioWeekDay";
import TimeToPost from "./schedule-components/TimeToPost";
import TimeZone from "./schedule-components/TimeZone";

import i18next from "../../../i18next";
const { t } = i18next;

export default function ScheduleSetup() {
    const { org_id, org_name } = useParams();

    setDocumentHeaders(t("schedule_setup_org_name",{org_name}));

    const [saving, set_saving] = useState(false);
    const [loading_schedule, set_loading_schedule] = useState(true);
    const [max_posts, set_max_posts] = useState(0);
    const [show_advanced_options, set_show_advanced_options] = useState(false);
    const [loading_first_posts, set_loading_first_posts] = useState(false);

    const navigate = useNavigate();

    const [global_state, set_global_state] = useState({
        _id: null,
        period: "day",
        posts_number: [
            { name: '2 posts', number: 2, inStock: true },
            { name: '3 posts', number: 3, inStock: true },
            //{ name: '4 posts', number : 4, inStock: true },
            //{ name: '5 posts', number : 5, inStock: true }
        ],
        number_of_posts: 2,
        time_to_post: [
            { 'name': 'time1', 'time': '', 'label': t("time_1") },
            { 'name': 'time2', 'time': '', 'label': t("time_2") },
            //{ 'name' : 'time3', 'time' : '', 'label' : 'Time 3'},
        ],
        day_time_to_post: [
            {
                id: 'monday',
                abbr: t("monday_abbr"),
                label: t("monday"),
                time: [
                    { 'name': 'time1', 'time': '', 'label': t("time_1") },
                ],
                checked: false
            },
            {
                id: 'tuesday',
                abbr: t("tuesday_abbr"),
                label: t("tuesday"),
                time: [
                    { 'name': 'time1', 'time': '', 'label': t("time_1") },
                ],
                checked: false
            },
            {
                id: 'wednesday',
                abbr: t("wednesday_abbr"),
                label: t("wednesday"),
                time: [
                    { 'name': 'time1', 'time': '', 'label': t("time_1") },
                ],
                checked: false
            },
            {
                id: 'thursday',
                abbr: t("thursday_abbr"),
                label: t("thursday"),
                time: [
                    { 'name': 'time1', 'time': '', 'label': t("time_1") },
                ],
                checked: false
            },
            {
                id: 'friday',
                abbr: t("friday_abbr"),
                label: t("friday"),
                time: [
                    { 'name': 'time1', 'time': '', 'label': t("time_1") },
                ],
                checked: false
            },
            {
                id: 'saturday',
                abbr: t("saturday_abbr"),
                label: t("saturday"),
                time: [
                    { 'name': 'time1', 'time': '', 'label': t("time_1") },
                ],
                checked: false
            },
            {
                id: 'sunday',
                abbr: t("sunday_abbr"),
                label: t("sunday"),
                time: [
                    { 'name': 'time1', 'time': '', 'label': t("time_1") },
                ],
                checked: false
            }
        ],
        words_number: 25,
        prefered_language: 'english',
        include_phone_number: false,
        include_email_address: false,
        include_website: true,
        include_images: true,
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        use_full_schedule : true
    });

    let prices = get_local_price_list();

    const handleClick = async (after_action) => {
        console.log(global_state);
        //Check if a selected day has a time
        let day_time_to_post = global_state.day_time_to_post;
        let time_to_post = global_state.time_to_post;
        let period = global_state.period;
        let posts_number = global_state.posts_number;
        let number_of_posts = global_state.number_of_posts;
        let words_number = global_state.words_number;
        let prefered_language = global_state.prefered_language;

        let error = false;
        let error_message = '';

        if (period === 'week') {
            day_time_to_post.forEach((day) => {
                if (day.checked) {
                    day.time.forEach((time) => {
                        if (time.time === '') {
                            error = true;
                            error_message = t("please_select_a_time_for_day",{day: day.label});
                        }
                    });
                }
            });
        } else {
            time_to_post.forEach((time) => {
                if(global_state.use_full_schedule){
                    if (time.time === '') {
                        error = true;
                        error_message = t("please_select_a_time");
                    }
                }else{
                    if(!window.confirm(t("you_are_saving_schedule_with_less_times_than_schedule"))){
                        return;
                    }
                }                
            });
        }

        if (error) {
            alert(error_message);
            return;
        }

        //Check if the number of posts is equal to the number of times selected
        let selected_posts = 0;
        posts_number.forEach((post) => {
            if (post.inStock) {
                selected_posts += post.number;
            }
        });

        //Check if the prefered language is selected
        if (prefered_language === '') {
            alert(t("please_select_the_prefered_language"));
            return;
        }

        let days_selected = 0;
        day_time_to_post.forEach((day) => {
            if (day.checked) {
                days_selected++;
            }
        });
        
        if (period === "week" && days_selected !== number_of_posts) {
            if (days_selected < number_of_posts) {
                if(global_state.use_full_schedule){
                    const diff = number_of_posts - days_selected;
                    alert(t("you_need_to_select_more",{diff }));
                    return;
                }else{
                    if(!window.confirm(t("you_are_saving_schedule_with_less_times_than_schedule"))){
                        return;
                    }
                }
            } else {
                const diff = days_selected - number_of_posts;
                alert(t("you_need_to_desect_day_on_the_schedule", {diff}));
                return;
            }
        }

        //Send the data to the server
        try {
            set_saving(true);
            if(!global_state._id) set_loading_first_posts(true);
            const res = await save_schedule({
                ...global_state,
                org_id
            });

            set_saving(false);
            if(!global_state._id) set_loading_first_posts(false);
            console.log(res);
            // return;
            if (res && res.schedule) {
                if (after_action === 'pay') {
                    navigate(`/pay/${org_id}/${org_name}`);
                } else {
                    window.location.href = "/";
                }
            } else if (res && res.error) {
                alert(res.error);
            }
        } catch (ex) {
            set_saving(false);
            if(!global_state._id) set_loading_first_posts(false);
            console.log(ex);
        }



        console.log(global_state);
    }

    const posts_per_month = global_state.period === "day" ? global_state.number_of_posts * 30 : global_state.number_of_posts * 4;

    //const monthly_price = parseFloat(parseFloat(posts_per_month) * 0.39).toFixed(2);


    const get_monthly_price = (posts_per_month) => {
        //Get monthly price from the prices object. Loop through the prices object and get the price that is greater than the posts_per_month
        let price = 0;
        for (let key in prices) {
            if (prices[key].posts >= posts_per_month) {
                price = prices[key].price;
                break;
            }
        }
        return price;

    }

    const monthly_price = get_monthly_price(posts_per_month);

    const get_org_info = async () => {
        try {
            set_loading_schedule(true);
            let res = await get_organization_details_info(org_id);

            if (res && res.org) {
                if (res.schedule && res.schedule.posts_number && !res.schedule.posts_number.length) {
                    delete res.schedule.posts_number;
                }
                res.schedule.status = "active";
                console.log("SCHEDULEE ", res.schedule);
                set_global_state({
                    ...global_state,
                    ...res.schedule
                })
                set_loading_schedule(false);
                if (res.last_payement) {
                    const regex = /Number of posts per month : (\d+)/i;
                    const match = res.last_payement.description.match(regex);
                    const number_of_posts_per_month = match ? parseInt(match[1]) : null;
                    set_max_posts(number_of_posts_per_month);
                }
            }
        } catch (ex) {
            set_loading_schedule(false);
        }
    }

    useEffect(() => {
        get_org_info();
    }, []);

    return (
        <DashboardLayout>
            {loading_first_posts ? <div className="col-span-12 ">
                <div className="w-full shadow text-center bg-white col-span-12 pt-8 pb-24 px-2 lg:px-1">
                    <div className="block w-full  text-center">
                        <div style={{ display: "flex", height: "100px", flexDirection: "column", textAlign: "center", alignItems: "center", justifyContent: "center" }}>
                            <Loading style={{ display: "inline-block" }} />
                        </div>
                    </div>
                    <div className="text-xl font-bold text-slate-500 px-20"> 🚀 🚀<br /> {t("pubcm_is_generating_your_first_posts")} <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></div>
                    <div className="text-slate-500">{t("they_will_be_ready_in_few_hang_on")}</div>
                </div>
            </div> :
                <div className="col-span-12 text-center">
                    {loading_schedule ? <div>Loading ... </div> : <div>
                        <div className="text-l text-center">
                            {/* <div className="text-center"><Cog6ToothIcon className="h-10 w-10 text-gray-400 inline"/></div> */}
                            {t("create_a_schedule_for_org_name_posts", {org_name})}</div>

                        {/* Good looking form asking user how many times he wants to post per week, per day */}
                        <div style={{ maxWidth: "600px" }} className="m-auto border justify-center shadow rounded-md">
                            <div className="my-6 bg-white mx-2 py-2 px-2 rounded-md shadow">
                                <RadioWeekDay max_posts={max_posts} state={global_state} update_fn={set_global_state} />
                            </div>
                            <div className="my-6 bg-white mx-2 py-2 px-2 rounded-md shadow">
                                <RadioPostsNumber max_posts={max_posts} state={global_state} update_fn={set_global_state} />
                            </div>

                            <div className="my-6 bg-white mx-2 py-2 px-2 rounded-md shadow">
                                <TimeToPost state={global_state} update_fn={set_global_state} />
                            </div>





                            <div className="my-6 bg-white mx-2 py-2 px-2 rounded-md shadow">
                                <PostingLanguage state={global_state} update_fn={set_global_state} />
                            </div>

                            <div className="py-2 cursor-pointer text-left pl-4">
                                <span onClick={() => set_show_advanced_options(!show_advanced_options)}>{t("advanced_options")} {!show_advanced_options ? <ChevronRightIcon className="h-5 inline" /> : <ChevronDownIcon className="h-5 inline" />}</span>
                            </div>

                            {/* Add tailwindcss switch asking to include phone number in posts or not */}
                            {show_advanced_options && <div>
                                <div className="my-6 bg-white mx-2 py-2 px-2 rounded-md shadow">
                                    <AddImages state={global_state} update_fn={set_global_state} />
                                </div>
                                <div className="my-6 bg-white mx-2 py-2 px-2 rounded-md shadow">
                                    <NumberWords state={global_state} update_fn={set_global_state} />
                                </div>

                                <div className="my-6 bg-white mx-2 py-2 px-2 rounded-md shadow">
                                    <TimeZone state={global_state} update_fn={set_global_state} />
                                </div>

                                <div className="my-6 bg-white mx-2 py-2 px-2 rounded-md shadow">
                                    <IncludeContact type="include_phone_number" label={t("phone_number")} org={{ name: org_name, id: org_id }} state={global_state} update_fn={set_global_state} />
                                </div>

                                <div className="my-6 bg-white mx-2 py-2 px-2 rounded-md shadow">
                                    <IncludeContact type="include_email_address" label={t("email_address")} org={{ name: org_name, id: org_id }} state={global_state} update_fn={set_global_state} />
                                </div>

                                <div className="my-6 bg-white mx-2 py-2 px-2 rounded-md shadow">
                                    <IncludeContact type="include_website" label={t("website")} org={{ name: org_name, id: org_id }} state={global_state} update_fn={set_global_state} />
                                </div>
                            </div>}

                            

                            <div className="my-6">
                                {!global_state._id ? <div className="flex justify-center">
                                    {/* <button onClick={() => handleClick("pay")} className="bg-pubcm2 hover:bg-pubcm1 text-white font-bold py-2 px-4 rounded">{t("pay_from_monthly_price",{ monthly_price })}</button> */}
                                    {/* <div className="inline-block ml-2 py-2 text-center"> {t("or")} </div> */}
                                    <button onClick={() => handleClick("save")} className="ml-2 border border-slate-600 border-2 px-4 rounded-md">
                                        {saving ? t("saving") : t("try_for_3_days")}
                                    </button>

                                </div> : <div>
                                    <button onClick={() => handleClick("save")} className="ml-2 bg-pubcm2 text-white py-2 px-4 rounded-md">
                                        {saving ? `${t("updating")}...` : t("udpdate_the_schedule")}
                                    </button>
                                </div>}

                                <div>{saving ? t("please_wait_while_we_are_generating_the_first_posts") : ''}</div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            }
        </DashboardLayout>
    );

}
