
import { Fragment } from 'react'
import { Menu, Popover, Transition } from '@headlessui/react'
import { EnvelopeIcon, MagnifyingGlassIcon, RectangleStackIcon } from '@heroicons/react/20/solid'
import { Bars3Icon, BellIcon, XMarkIcon, Cog6ToothIcon} from '@heroicons/react/24/outline'

import { useState } from 'react';

import icon from '../res/img/icon-transparent.png';

import "./dashboard_layout.css";
import { Link } from 'react-router-dom';
import SlideOverMenus from './SlideOverMenus';
import SlideOverActions from './SlideOverActions';
import LanguageSelector from './LanguageSelector';

import i18next from '../i18next';

const { t } = i18next;

const user_obj = localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));

const user = {
  name: user_obj && user_obj.names,
  email: user_obj && user_obj.email,
  imageUrl:
  user_obj && user_obj.picture,
}
const navigation = [

]
const userNavigation = [
  { name: t("signout"), href: '', onClick : () => {localStorage.clear();window.location.href="/auth/login";} },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}



export default function DashboardLayout(props) {
  console.log(props.user_orgs);

  if (!localStorage || !localStorage.getItem("token")) {
      window.location.href = "/auth/login";
  }

  const [menus_state, set_menus_state] = useState({
    open : false
  });

  const fn_open_menus_slide_over = () => {
    set_menus_state({
      open : true
    });
  }

  const handleClickMoreMenus = () => {
    fn_open_menus_slide_over();
  }

  const go_to_action_menus = () => {
    window.location.href = "#actions_menus";
    setTimeout(function(){
      window.history.pushState({}, '', '/');
    }, 2000);
  }


  const user_local_obj = localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));
  
  return (
    <div className="min-h-screen bg-gray-100">
      <Popover
        as="header"
        className={({ open }) =>
          classNames(
            open ? 'fixed inset-0 z-40 overflow-y-auto' : '',
            'bg-white shadow-sm lg:static lg:overflow-y-visible'
          )
        }
      >
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 lg:px-8">
              <div className="relative flex justify-between lg:gap-8 xl:grid xl:grid-cols-12 py-4">
                <div className="flex md:absolute md:inset-y-0 md:left-0 lg:static xl:col-span-2">
                  <div className="flex flex-shrink-0 items-center">
                    <Bars3Icon className="mr-6 h-6 w-6 text-gray-400 hover:text-gray-500" onClick={handleClickMoreMenus}/>
                    <a href="/">
                      <img
                        className="block h-7 w-auto inline"
                        src={icon}
                        alt="flitter"
                      /> <div className='inline relative top-1'>flitter</div>
                    </a>
                    {/* <div className='mx-1'><span className='text-orange-300 text-xs px-2 rounded-md'><small>Alpha</small></span></div> */}
                  </div>
                </div>
                <div className="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6">
                  <div className="flex items-center px-6 py-4 md:mx-auto md:max-w-3xl lg:mx-0 lg:max-w-none xl:px-0">
                    
                  </div>
                </div>
                <div className="flex items-center md:absolute md:inset-y-0 md:right-0 lg:hidden">
                  {/* Mobile menu button */}
                  {/* <Popover.Button id="btnClosePopoverMenus" className="-mx-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Popover.Button> */}
                    <span key="link_to_actions" onClick={go_to_action_menus}><Cog6ToothIcon className="block h-6 w-6" aria-hidden="true" /></span>
                </div>

                <div className="flex lg:items-center lg:justify-end xl:col-span-4">
                  {/* <a
                    href="#"
                    className="ml-5 flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </a> */}

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-5 flex-shrink-0">
                      <div>
                      
                      <Menu.Button className="flex rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <span className="sr-only">{t("open_user_menu")}</span>
                        <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          <LanguageSelector/>
                        </Menu.Item>
                        {userNavigation.length ? userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <a
                                href={item.href}
                                onClick={item.onClick}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block py-2 px-4 text-sm text-gray-700'
                                )}
                              >
                                {item.name}
                              </a>
                            )}
                          </Menu.Item>
                        )) : (
                          <Menu.Item>
                            {({ active }) => (
                              <Link to="/">
                                {t("go_to_the_dashboard")}
                              </Link>
                            )}
                          </Menu.Item>
                        )}
                      </Menu.Items>
                    </Transition>
                  </Menu>

                  

                  {user_local_obj && user_local_obj.role && user_local_obj.role === "superadmin" && <Link to="/admin/">
                    <RectangleStackIcon className="ml-6 h-6 w-6 text-gray-400 hover:text-gray-500" aria-hidden="true" />
                  </Link>}

                  
                  
                  {/* {user_local_obj && user_local_obj.role && user_local_obj.role === "superadmin" && <Link to="/admin/news">
                    <EnvelopeIcon className="ml-6 h-6 w-6 text-gray-400 hover:text-gray-500" aria-hidden="true" />
                  </Link>} */}
                  
                </div>
              </div>
            </div>

            <Popover.Panel as="nav" className="lg:hidden" aria-label="Global">
              <div className="mx-auto max-w-3xl space-y-1 px-2 pt-2 pb-3 sm:px-4">
                {props.user_orgs && props.user_orgs.map(({org},i ) => (
                  <a
                    key={org.name + "" + i}
                    aria-current={org._id === props.current_org_id ? 'page' : undefined}
                    onClick={() => props.change_org_fn(i, "close")}
                    className={classNames(
                      org._id === props.current_org_id ? 'bg-gray-100 text-gray-900' : 'hover:bg-gray-50',
                      'block rounded-md py-2 px-3 text-base font-medium'
                    )}
                  >
                    {org.name}
                  </a>
                ))}

                {/* <div>
                  <Link
                    to="/new/organization"
                    className="ml-6 inline-flex items-center rounded-md border border-transparent bg-pubcm2 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-pubcm1 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    New organisation
                  </Link>
                </div> */}
              </div>
              <div className="border-t border-gray-200 pt-4 pb-3">
                <div className="mx-auto flex max-w-3xl items-center px-4 sm:px-6">
                  <div className="flex-shrink-0">
                    <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-gray-800">{user.name}</div>
                    <div className="text-sm font-medium text-gray-500">{user.email}</div>
                  </div>
                  {/* <button
                    type="button"
                    className="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button> */}
                </div>

                <div className="mx-auto my-4 max-w-3xl space-y-1 px-2 sm:px-4">
                {user_local_obj && user_local_obj.role && user_local_obj.role === "superadmin" && <Link to="/admin/">
                    <Cog6ToothIcon className="ml-6 h-6 w-6 text-gray-400 hover:text-gray-500 inline" aria-hidden="true" /> {t("manage")}
                  </Link>}
                </div>
                
                <div className="mx-auto mt-3 max-w-3xl space-y-1 px-2 sm:px-4">
                  {userNavigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      onClick={item.onClick}
                      className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>

      <div className="py-6">
        <div className="mx-auto max-w-3xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-12 lg:gap-2 lg:px-2">
          <SlideOverMenus current_org_id={props.current_org_id} orgs={props.user_orgs} change_org_fn={props.change_org_fn} state={menus_state} fn_state={set_menus_state}/>
          {/* <SlideOverActions current_org_id={props.current_org_id} orgs={props.user_orgs} change_org_fn={props.change_org_fn} state={menus_state} fn_state={set_menus_state}/> */}
          { props.children }
        </div>
      </div>
    </div>
  )
}
