import { Fragment, useEffect, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { get_org_admins, org_add_admin, org_remove_admin } from '../utils/api'
import Alert from './Alert'
import { Link } from 'react-router-dom'

import i18next from '../i18next';

const { t } = i18next;

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function SlideOverMenus(props) {
  console.log(props.orgs);
  const setOpen = () => {
    props.fn_state({ open: false });
  }

  const menus = [
    {
      name: t("administrators"),
    }
  ]

  return (
    <Transition.Root show={props.state.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10 sm:pr-16">
            <Transition.Child
                  as={Fragment} 
                  enter="transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0" 
                  leaveTo="-translate-x-full"
                >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="p-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">{t("your_organizations")}</Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-pubcm1"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">{t("close_panel")}</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    
                    <ul role="list" className="flex-1 divide-y divide-gray-200 overflow-y-auto">
                      {props.orgs && props.orgs.map(({org}, index) => (
                        <li key={`menu${org.name}`} className={org._id === props.current_org_id ? 'bg-slate-200 text-gray-900' : ''} onClick={() => {setOpen(false); props.change_org_fn(index)}}>
                          <div className="group relative flex items-center py-6 px-5">
                            <a href="#" className="-m-1 block flex-1 p-1">
                              <div className="absolute inset-0 group-hover:bg-gray-50" aria-hidden="true" />
                              <div className="relative flex min-w-0 flex-1 items-center">
                                <span className="relative inline-block flex-shrink-0">
                                  
                                </span>
                                <div className="ml-4 truncate">
                                  <p className="truncate text-sm font-medium text-gray-900">{org.name}</p>
                                </div>
                              </div>
                            </a>
                          </div>
                        </li>
                      ))}
                    </ul>

                    
                    <div className='fixed bottom-1 left-1'>
                      <Link
                        to="/new/organization"
                        className="ml-6 inline-flex items-center rounded-md border border-transparent bg-pubcm2 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-pubcm1 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                       <PlusIcon className='h-5 w-5 inline mr-2'/> {t("add_a_new_organization")}
                      </Link>

                      {/* {props.user_orgs && props.user_orgs.length ? <Link
                    to="/new/organization"
                    className="ml-6 inline-flex items-center rounded-md border border-transparent bg-pubcm2 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-pubcm1 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    New organisation
                  </Link> : ''} */}

                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
