import DashboardLayout from "../../components/DashboardLayout";

import { CheckIcon, ClockIcon } from '@heroicons/react/24/outline'
import { Link, useParams } from "react-router-dom";
import { getPayementStatus, get_organization_details, get_stripe_session_url } from "../../utils/api";
import { useEffect, useState } from "react";
import human from "human-time";
import failed_pyt from "../../res/img/fall_coming.svg";
import winners from "../../res/img/winners.svg";

import i18next from "../../i18next";
const { t } = i18next;

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function AfterPayLS(props) {
    const { org_id, org_name, pyt_uuid } = useParams();
    const [global_state, set_global_state] = useState({
        cancelled : false,
        success : false,
        failed : false,
        pending : false,
    });
    //get stripe status query param  
    
    const get_pyt_info = async () => {
        const response = await getPayementStatus(pyt_uuid);
        if(response && response.payement){
            if(response.payement.status === "paid"){
                set_global_state({
                    ...global_state,
                    success : true,
                });
            }else if(response.payement.status === "pending"){
                set_global_state({
                    ...global_state,
                    pending : true,
                });
            }else if(response.payement.status === "cancelled"){
                set_global_state({
                    ...global_state,
                    cancelled : true,
                });
            }else{
                set_global_state({
                    ...global_state,
                    failed : true,
                });
            }
        }
    }

    useEffect(() => {
        get_pyt_info();
    }, []);

    return (
        <DashboardLayout>
            <div className="mx-auto max-w-7xl bg-white col-span-12 pt-8 pb-24 px-6 lg:px-8">
                <div className="text-center my-2 mb-6">
                    {global_state.success && <img className="w-60 inline-block" src={winners}/>}
                    {(global_state.cancelled || global_state.pending) && <img className="w-60 inline-block" src={failed_pyt}/>}
                    {global_state.failed && <img className="w-60 inline-block" src={failed_pyt}/>}
                </div>
                <div className="flex flex-col lg:flex-row">
                    <div className="flex-1">
                        {global_state.success && <h1 className="text-2xl font-bold text-gray-900">{t("thank_you_for_your_purchase")}</h1>}
                        {global_state.cancelled && <h1 className="text-2xl font-bold text-gray-900">{t("your_pyt_was_cancelled")}</h1>}
                        {global_state.failed && <h1 className="text-2xl font-bold text-gray-900">{t("your_pyt_failed")}</h1>}
                        {global_state.pending && <h1 className="text-2xl font-bold text-gray-900">{t("your_pyt_is_still_pending")}</h1>}

                        {global_state.success && <p className="mt-1 text-sm text-gray-500">{t("your_pyt_has_been_successfully_processed")}</p>}
                        {global_state.cancelled && <p className="mt-1 text-sm text-gray-500">{t("you_can_try_again_later")}</p>}
                        {global_state.failed && <p className="mt-1 text-sm text-gray-500">{t("you_can_try_again_later")}</p>}
                        {global_state.pending && <p className="mt-1 text-sm text-gray-500">{t("please_proceed_to_pyt_try_again_later")}</p>}
                    </div>
                    <div className="mt-6 flex lg:mt-0 lg:ml-4">
                        <span className="sm:ml-3">
                            {(global_state.success || global_state.pending) && <Link to={`/`}>
                                <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-pubcm1 hover:bg-pubcm2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500">
                                    {t("go_to_dashboard")}
                                </button>
                            </Link>}
                            {(global_state.cancelled || global_state.pending) && <Link to={`/pay/${org_id}/${org_name}`}>
                                <button type="button" className="ml-1 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-slate-600 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500">
                                    {t("try_again")}
                                </button>
                            </Link>}
                            {global_state.failed && <Link to={`/pay/${org_id}/${org_name}`}>
                                <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500">
                                {t("try_again")}
                                </button>
                            </Link>}
                        </span>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}
