import icon from '../../res/img/icon-transparent.png';

import { GoogleLogin, GoogleOAuthProvider, useGoogleLogin, } from '@react-oauth/google';
import { FacebookProvider, LoginButton, useLogin } from 'react-facebook';
import { get_user_info, sign_with_email, signup_with_email } from '../../utils/api';
import { useEffect, useState } from 'react';
//const { OAuth2Client } = require('google-auth-library');
import { LinkedIn, useLinkedIn } from 'react-linkedin-login-oauth2';
// You can use provided image shipped by this package or using your own
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';
import linkedinIcon from '../../res/img/linkedin-icon.png';
import { openLinkedinLogin } from '../../utils/channels';
import i18next from '../../i18next';
import { useParams } from 'react-router-dom';
import { Dot } from 'react-animated-dots';

import flitterLogo from '../../res/img/flitter-logo.png';

const { t } = i18next;

export default function AuthLayout(props) {
  return (
    <>
      <div className="flex min-h-full">
        <div className="flex flex-1 justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <a href="/"><img
                className="h-8 w-auto inline"
                src={icon}
                alt="Flitter"
              /> {t("flitter")}</a>

              <h2 className="mt-10 text-2xl py-3 font-bold tracking-tight text-gray-900">{props.title ? props.title : t("welcome_to_pubcm_let_ai_make_you_more_productive")}</h2>
              <p className="mt-2 text-sm text-gray-600 hidden">
                {t("Or")}{' '}
                <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                  {t("start_your_14_day_free_trial")}
                </a>
              </p>
            </div>



            <div className='my-2'>

            </div>

            {props.children}

          </div>
        </div>
        <div className="relative hidden w-0 border-l flex-1 lg:block" style={{ height: (window.screen.height - 50) + "px" }}>
          <div className='h-full flex items-center justify-center '>
            <img
              className=" inset-0 h-24"
              src={flitterLogo}
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  )
}
