import React, { useEffect, useRef, useState } from 'react';
import i18next from '../../i18next';
import DashboardLayout from '../../components/DashboardLayout';
import { Stage, Layer, Image, Text, Rect, Transformer } from 'react-konva';
import './photo_editor.css';

const { t } = i18next;

export function EditPhoto(props) {
  const [image, setImage] = useState(null);
  const transformerRef = useRef(null);
  const imageRef = useRef(null);

  const stageRef = useRef(null);
  const [text, setText] = useState('Double-click to edit');
  const [isEditing, setIsEditing] = useState(false);
  const [position, setPosition] = useState({ x: 50, y: 50 });


  useEffect(() => {
    loadImage('https://images.pexels.com/photos/6446708/pexels-photo-6446708.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940').then(setImage);
  }, []);

  const loadImage = (image_url) => {
    return new Promise((resolve, reject) => {
      const img = new window.Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = image_url;
    });
  }

  const handleInputChange = (e) => {
    setText(e.target.value);
  };

  const handleBlur = () => {
    // Hide the input and show the Konva Text
    setIsEditing(false);
  };

  const handleDoubleClick = (e) => {
    const stage = stageRef.current;
    const textPosition = e.target.getAbsolutePosition();

    // Position for the input
    setPosition(textPosition);

    // Hide the Konva Text
    setIsEditing(true);
  };

  useEffect(() => {
    if (transformerRef.current && imageRef.current) {
      // Update Transformer to attach to the current image
      transformerRef.current.nodes([imageRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [image]);

  return (
    <DashboardLayout>
      <div className="col-span-12 bg-white text-center px-4 md:px-8 py-2 pt-7 text-left">
        <div>
          <Stage id="image_editor" className='' width={600} height={600}>
            <Layer className='border'>
              {image && <Image
                image={image}
                x={0}
                y={0}
                width={600}
                height={512}
                ref={imageRef}
                draggable
              />}
              <Text
                text="Draggable Text"
                onDblClick={handleDoubleClick}
                // x={this.state.x}
                // y={this.state.y}
                draggable
                onDragStart={() => {
                  // this.setState({
                  //   isDragging: true,
                  // });
                }}
                onDragEnd={(e) => {
                  // this.setState({
                  //   isDragging: false,
                  //   x: e.target.x(),
                  //   y: e.target.y(),
                  // });
                }}
              />

              {/* Add rectange */}
              <Rect
                x={0}
                y={500}
                width={600}
                height={100}
                fill="#000"
                shadowBlur={0}
                opacity={0.4}
                draggable
                onDragEnd={(e) => {
                  // this.setState({
                  //   x: e.target.x(),
                  //   y: e.target.y(),
                  // });
                }}
              />

              <Rect
                x={0}
                y={0}
                width={350}
                height={600}
                fill="#000"
                shadowBlur={0}
                opacity={0.3}
                draggable
                onDragEnd={(e) => {
                  // this.setState({
                  //   x: e.target.x(),
                  //   y: e.target.y(),
                  // });
                }}
              />

              <Transformer
                ref={transformerRef}
                keepRatio
                centeredScaling
              />
            </Layer>

            <Layer>
              <Text
                text={text}
                x={50}
                y={50}
                fontSize={24}
                draggable
                onDblClick={handleDoubleClick}
              />
            </Layer>
          </Stage>
          {isEditing && (
            <input
              style={{
                position: 'absolute',
                top: position.y + 'px',
                left: position.x + 'px',
                width: 'auto',
              }}
              type="text"
              value={text}
              onChange={handleInputChange}
              onBlur={handleBlur}
              autoFocus
            />
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}
