import { BrowserRouter,  Routes, Route} from "react-router-dom";
import AdminHome from "./pages/admin/AdminHome";
import News from "./pages/admin/News";
import Users from "./pages/admin/Users";
import { AfterSocial } from "./pages/aftersocial/AfterSocial";
import AfterSocialLinkedIn from "./pages/aftersocial/AfterSocialLinkedIn";
import LinkedinLogin from "./pages/aftersocial/LinkedinLogin";
import Fb from "./pages/auth/Fb";
import Login from "./pages/auth/Login";
import ScheduleSetup from "./pages/dashboard/components/ScheduleSetup";
import Dashboard from "./pages/dashboard/Dashboard";
import { EditOrganization } from "./pages/dashboard/EditOrganization";
import { FacebookPages } from "./pages/dashboard/FacebookPages";
import { NewOrganization } from "./pages/dashboard/NewOrganization";
import { Objectives } from "./pages/objectives/Objectives";
import AfterPay from "./pages/pay/AfterPay";
import AfterPayLS from "./pages/pay/AfterPayLS";
import Pay from "./pages/pay/Pay";
import PayLemonSqueezy from "./pages/pay/PayLemonSqueezy";
import Waitlist from "./pages/waitlist/Waitlist";
import { MediaFiles } from "./pages/media/MediaFiles";
import PickLinkedinOrg from "./pages/aftersocial/PickLinkedinOrg";
import { AfterSocialInstagram } from "./pages/aftersocial/AfterSocialInstagram";
import MarketingStudio from "./pages/marketing_studio/MarketingStudio";
import { OnboardingOrg } from "./pages/onboarding_org/OnboardingOrg";
import Products from "./pages/products/Products";
import { EditPhoto } from "./pages/media/EditPhoto";
import Signup from "./pages/auth/Signup";
import VerifyEmail from "./pages/auth/VerifyEmail";
import AskResetOTP from "./pages/auth/AskResetOTP";
import ResetPassword from "./pages/auth/ResetPassword";
import Privacy from "./pages/privacy/privacy";

export default function MainRouter(){
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Dashboard/>}/>
                    <Route path="/auth/fb" element={<Fb/>}/>
                    <Route path="/auth/login" element={<Login/>}/>
                    <Route path="/auth/signup" element={<Signup/>}/>
                    <Route path="/auth/verify/email" element={<VerifyEmail/>}/>
                    <Route path="/auth/ask_reset/otp" element={<AskResetOTP/>}/>
                    <Route path="/auth/reset/pwd" element={<ResetPassword/>}/>
                    <Route path="/privacy" element={<Privacy/>}/>
                    {/* <Route path="/new/organization" element={<NewOrganization/>}/> */}
                    <Route path="/new/organization" element={<OnboardingOrg/>}/>
                    <Route path="/facebook/pages" element={<FacebookPages/>}/>
                    <Route path="/instagram/pages" element={<AfterSocialInstagram/>}/>

                    <Route path="/linkedin/connect" element={<AfterSocialLinkedIn/>}/>
                    <Route path="/linkedin/login" element={<LinkedinLogin/>}/>
                    <Route path="/linkedin/pick/org/:org_id/:org_name" element={<PickLinkedinOrg/>}/>


                    <Route path="/aftersocial/:social_network" element={<AfterSocial/>}/>
                    <Route path="/schedule/setup/:org_id/:org_name" element={<ScheduleSetup/>}/>
                    <Route path="/sorry" element={<Waitlist/>}/>
                    {/* <Route path="/pay/:org_id/:org_name" element={<Pay/>}/> */}
                    <Route path="/pay/:org_id/:org_name" element={<PayLemonSqueezy/>}/>
                    <Route path="/str_pyt/:org_id/:org_name" element={<AfterPay/>}/>
                    <Route path="/ls_pyt/:org_id/:pyt_uuid/:org_name" element={<AfterPayLS/>}/>

                    <Route path="/admin/users" element={<Users/>}/>
                    <Route path="/admin/" element={<AdminHome/>}/>

                    <Route path="/manage/org/:org_id" element={<EditOrganization/>}/>
                    <Route path="/manage/org/objective/:org_id/:org_name" element={<Objectives/>}/>
                    <Route path="/admin/news" element={<News/>}/>

                    <Route path="/manage/org/media/:org_id/:org_name" element={<MediaFiles/>}/>
                    <Route path="/photo/edit/" element={<EditPhoto/>}/>

                    <Route path="/manage/org/marketing_studio/:org_id/:org_name" element={<MarketingStudio/>}/>

                    <Route path="/manage/org/products/:org_id/:org_name" element={<Products/>}/>
                    <Route path="/welcome/:referral_code" element={<Login/>}/>
                    
                </Routes>
            </BrowserRouter>
        </div>
    );
}