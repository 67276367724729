import { DocumentDuplicateIcon } from "@heroicons/react/20/solid";
import i18next from "../../../i18next"
import { useRef, useState } from "react";
import { generate_promo_code } from "../../../utils/api";
import NotificationWidget from "../../../components/NotificationWidget";

export default function AffiliateProgramWidget(props) {
    const { t } = i18next;

    const promo_code = props.promo_code;

    const [promoCode, setPromoCode] = useState(promo_code ? promo_code.code || "" : "");
    const [loading_promo_code, set_loading_promo_code] = useState(false);
    const [show_create_link, set_show_create_link] = useState(false);
    const copyTextRef = useRef(null);
    const [notif_obj, set_notif_obj] = useState({title : "", description : "", timeout : 3000});

    const handle_promo_change = (e) => {
        setPromoCode(e.target.value);
    }

    const handle_copy_click = () => {
        navigator.clipboard.writeText(t("here_is_the_best_ai_app_to_automate_generation_planning_and_posting", { link: copyTextRef.current.innerText }));
        set_notif_obj({
            ...notif_obj,
            title : t("linked_was_copied"),
            description: t("paste_the_link_on_social_media_and_other_website")
        })
    }

    const handle_request_promo_code = async (e) => {
        e.preventDefault();
        try {
            set_loading_promo_code(true);
            const res = await generate_promo_code({
                promo_code: promoCode
            });
            console.log(res);
            set_loading_promo_code(false);
            if (res && res.suggested_promo_code) {
                setPromoCode(res.suggested_promo_code);
                set_show_create_link(false);
            }
            console.log(res);
        } catch (ex) {
            set_loading_promo_code(false);
            console.log(ex);
        }
    }

    return <div>
        {(notif_obj.title) && <NotificationWidget title={notif_obj.title} description={notif_obj.description} timeout={notif_obj.timeout}/>}
        <div className="text-xl font-bold">{t("affiliate_program")}</div>
        <div className="text-md py-2">{t("benefit_up_to_amount_by_referring_our_app", { amount: "5%" })}</div>
        <div>

        </div>
        {!show_create_link && <div>
            {promoCode && <div>
                <div className="text-sm">{t("use_your_affiliate_link_to_refer_users_to_pubcm")}</div>
                <div className="w-full">
                    <div className="text-md border py-2 px-1" ref={copyTextRef}>
                        https://app.flitter.pro/welcome/{promoCode}
                    </div>
                    <div className="border">
                        <button onClick={handle_copy_click} className="mx-1 my-1 py-1 bg-slate-700 text-white py-2 px-4 my-1 w-full rounded-md hover:bg-pubcm1"><DocumentDuplicateIcon className="h-4 w-4 inline" /> {t("copy_link")}</button>
                    </div>
                </div>
            </div>}

            {!promoCode && <div className="my-2 p-2 bg-gradient-to-r from-green-200 to-sky-300">
                <div>{t("create_new_affiliate_link")} </div>
                <div>
                    <button onClick={() => set_show_create_link(!show_create_link)} className="bg-slate-700 text-white py-2 px-4 my-1 w-full rounded-md hover:bg-pubcm1">
                        {t("create_new_link")}
                    </button>
                </div>
            </div>}

            {promoCode && <div className="my-2 text-center">
                <span onClick={() => set_show_create_link(!show_create_link)} className="underline text-blue-600 hover:font-bold cursor-pointer">{t("create_new_link")}</span>
            </div>}
        </div>}

        {show_create_link && <div className="">
            <form onSubmit={handle_request_promo_code}>
                <div className="my-2">
                    <input type="text" onChange={handle_promo_change} className="w-full border border-1 border-slate-200 py-2 rounded-md focus:ring-pubcm1" maxLength={5} placeholder="PROMO CODE" />
                </div>
                <div className="flex">
                    <button type="submit" disabled={loading_promo_code} className="bg-slate-700 flex-2 text-white py-2 px-4 my-1 w-full rounded-md hover:bg-pubcm1">
                        {!loading_promo_code ? t("get_promo_code") : t("getting_promo_code")}
                    </button>
                    <button onClick={() => set_show_create_link(!show_create_link)} className="mx-1 border border-slate-700 flex-1 text-slate-700 text-white py-2 px-2 my-1 w-full rounded-md hover:border-pubcm1 hover:text-pubcm1">{t("cancel")}</button>
                </div>
            </form>
        </div>}


    </div>
}

